import { useSalesSummaryPageLazyQuery } from '../../../../services';
import {
    CTableWrapper,
    DBlock,
    DBlockHeader,
    DIdAutocomplete,
    DPage,
    DPageHeader,
    DYear,
    ICustomTableWrapperItem,
    useDYear,
} from '../../../dum';
import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { formatFloat, getDateRange, useBoolean } from '../../../../helpers';
import dayjs from 'dayjs';
import { DCheckbox } from '../../../dum/simple/inputs/d-checkbox';
import { SalesSummaryExcelExportButton } from './sales-summary-excel-export-button';

type TotalRange = { year: number; month: number } & {
    total: number;
    total_with_tax: number;
    tax: number;
    kilos_sold: number;
};

export function SalesSummary() {
    const [totalRange, setTotalRange] = useState<TotalRange[]>([]);
    const [productCategoryRange, setProductCategoryRange] = useState<
        (Record<string, number> & { year: number; month: number })[]
    >([]);

    const [selectedAccountId, setSelectedAccountId] = useState<number | null>(
        null,
    );

    const [onlyOwnProducts, setOnlyOwnProducts] = useBoolean({
        KEY: SalesSummary.name + '_OnlyOwnProducts',
        defaultValue: true,
    });
    const [productCategoryTableItems, setProductCategoryTableItems] = useState<
        ICustomTableWrapperItem[]
    >([]);

    const { year, setYear } = useDYear({
        COMPONENT_KEY: SalesSummary.name + '_Year',
    });

    const [query, { data, loading }] = useSalesSummaryPageLazyQuery({
        onCompleted: (data) => {
            if (year) {
                const totalMonthRange = getDateRange({
                    month: null,
                    year: year,
                    content: {
                        total: 0,
                        total_with_tax: 0,
                        tax: 0,
                        kilos_sold: 0,
                    },
                });

                data.getTotalSalesSummary.sales.forEach((sale) => {
                    const foundMonthRange = totalMonthRange.find((mr) => {
                        return mr.year === sale.year && mr.month === sale.month;
                    });
                    if (foundMonthRange) {
                        foundMonthRange.total = sale.total;
                        foundMonthRange.tax = sale.tax;
                        foundMonthRange.total_with_tax = sale.total_with_tax;
                        foundMonthRange.kilos_sold = sale.kilos_sold;
                    }
                });

                // const order-sale = data.getAccounts.reduce((acc, curr) => {
                //     acc[String(curr.id)] = {
                //         total: 0,
                //     };
                //     return acc;
                // }, {} as Record<string, any>);

                const productCategoriesDictionary =
                    data.getProductCategories.reduce((acc, curr) => {
                        if (
                            curr.order_production_type_id === 1 ||
                            !curr.order_production_type_id
                        ) {
                            acc[String(curr.id)] = 0;
                        }
                        return acc;
                    }, {} as Record<string, number>);

                productCategoriesDictionary['0'] = 0;

                const productCategoryMonthRange = getDateRange({
                    month: null,
                    year: year,
                    content: productCategoriesDictionary,
                });

                data.getProductCategorySalesSummary.sales.forEach((sale) => {
                    if (!sale.product_category_id) {
                        console.log(sale);
                    }

                    const foundMonthRange = productCategoryMonthRange.find(
                        (dr) => {
                            return (
                                dr.year === sale.year && sale.month === dr.month
                            );
                        },
                    );
                    if (foundMonthRange) {
                        foundMonthRange[
                            sale.product_category_id
                                ? String(sale.product_category_id)
                                : '0'
                        ] += sale.kilos_sold;
                    }
                });

                setProductCategoryTableItems(
                    data.getProductCategories
                        .filter(
                            (pc) =>
                                pc.order_production_type_id === 1 ||
                                !pc.order_production_type_id,
                        )
                        .map((pc) => {
                            return {
                                title: pc.name,
                                content: (item: Record<string, number>) => {
                                    return `${formatFloat(
                                        item[String(pc.id)],
                                    )} kg`;
                                },
                            };
                        }),
                );

                setProductCategoryRange(productCategoryMonthRange);

                setTotalRange(totalMonthRange as TotalRange[]);
            } else {
                setTotalRange([]);
            }
        },
    });

    const accounts = data?.getAccounts || [];

    const products: { kilos: number; total: number; product: string | null }[] =
        useMemo(() => {
            const localProducts: {
                kilos: number;
                total: number;
                product: string | null;
            }[] = [];

            data?.getProductAccountSalesSummary?.sales?.forEach(
                (accountProduct) => {
                    if (accountProduct.account_id === selectedAccountId) {
                        localProducts.push({
                            kilos: accountProduct.kilos_sold,
                            total: accountProduct.total_with_tax,
                            product: accountProduct?.product_name || null,
                        });
                    }
                },
            );

            localProducts.sort((a, b) => {
                return b.kilos - a.kilos;
            });

            return localProducts;
        }, [data, selectedAccountId]);

    useEffect(() => {
        void query({
            variables: {
                year: year,
                onlyOwnProducts: onlyOwnProducts,
            },
        });
    }, [query, year, onlyOwnProducts]);

    return (
        <DPage>
            <DPageHeader title={'Resumen de ventas'} />
            <DBlock>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Box sx={{ flex: 1, mr: 2 }}>
                        <DYear setYear={setYear} year={year} />
                    </Box>
                    <Box sx={{ mx: 1 }}>
                        <DCheckbox
                            align={'left'}
                            disableGutters
                            onChange={(val) => {
                                setOnlyOwnProducts(val);
                            }}
                            value={onlyOwnProducts}
                            label={'Solo productos internos'}
                        />
                    </Box>
                    <Box>
                        <SalesSummaryExcelExportButton
                            sales={data?.getProductSalesSummary.sales}
                            disabled={loading}
                        />
                    </Box>
                </Box>
            </DBlock>
            <DBlock>
                <DBlockHeader subtitle={'Totales'} />
                <CTableWrapper
                    items={[
                        {
                            title: 'Mes',
                            content: (item) => {
                                return dayjs()
                                    .set('month', item.month - 1)
                                    .format('MMMM');
                            },
                        },
                        {
                            title: 'Kilos vendidos',
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item.kilos_sold) + ' kg';
                            },
                        },
                        {
                            title: 'Total',
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item.total);
                            },
                        },
                        {
                            title: 'IVA',
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item.tax);
                            },
                        },
                        {
                            title: 'Total (IVA incluido)',
                            textAlign: 'right',
                            content: (item) => {
                                return formatFloat(item.total_with_tax);
                            },
                        },
                    ]}
                    data={totalRange}
                />
            </DBlock>
            <DBlock>
                <DBlockHeader subtitle={'Categorias'} />
                <CTableWrapper
                    items={[
                        {
                            title: 'Mes',
                            content: (item) => {
                                return dayjs()
                                    .set('month', item.month - 1)
                                    .format('MMMM');
                            },
                        },
                        ...productCategoryTableItems,
                        {
                            title: 'Otros',
                            content: (item) => {
                                return formatFloat(item['0']) + ' kg';
                            },
                        },
                    ]}
                    data={productCategoryRange}
                />
            </DBlock>
            <DBlock>
                <DBlockHeader subtitle={'Productos por cliente'}>
                    <DIdAutocomplete
                        id={selectedAccountId}
                        label={'Cliente'}
                        options={accounts}
                        itemText={'name'}
                        onChange={(item) => {
                            setSelectedAccountId(item);
                        }}
                    />
                </DBlockHeader>
                <CTableWrapper
                    items={[
                        {
                            title: 'Producto',
                            content: (item) => {
                                return item.product;
                            },
                        },
                        {
                            title: 'Kilos',
                            content: (item) => {
                                return formatFloat(item['kilos']) + ' kg';
                            },
                        },
                        {
                            title: 'Total',
                            content: (item) => {
                                return formatFloat(item['total']);
                            },
                        },
                    ]}
                    data={products}
                />
            </DBlock>
        </DPage>
    );
}
