import React from 'react';
import { RhColumns, RHTextField } from '../../../../../../../dum';
import { GroupsInput } from './groups/groups-input';
import { KiloPriceInput } from './kilo-price/kilo-price-input';
import { KilosInput } from './kilos/kilos-input';
import { OrderSaleProductInput } from './product/product-input';
import { IOrderSale, ORDER_ADJUSTMENT_PRODUCTS } from '../../order-sale-form';
import { useCalculateProductsTotal } from '../../helpers/use-calculate-products-total';
import { GroupPriceInput } from './group-price/group-price-input';
import { useFormContext } from 'react-hook-form';

const defaultValue: IOrderSale['order_sale_products'][number] = {
    product: null,
    kilos: 0,
    groups: 0,
    group_weight: 0,
    kilo_price: 0,
    group_price: 0,
    total: 0,
};

type TOrderSaleProduct = keyof IOrderSale['order_sale_products'][number];

export const ORDER_SALE_PRODUCTS: keyof IOrderSale = 'order_sale_products';
export const PRODUCT: TOrderSaleProduct = 'product';
export const KILOS: TOrderSaleProduct = 'kilos';
export const KILO_PRICE: TOrderSaleProduct = 'kilo_price';
export const GROUP_PRICE: TOrderSaleProduct = 'group_price';
export const GROUP_WEIGHT: TOrderSaleProduct = 'group_weight';
export const TOTAL: TOrderSaleProduct = 'total';

export function OrderSaleProductsColumns() {
    const rx = useFormContext();
    const { calculateProductsTotal } = useCalculateProductsTotal();

    return (
        <RhColumns
            title={'Productos'}
            name={ORDER_SALE_PRODUCTS}
            defaultValue={defaultValue}
            onDelete={() => {
                calculateProductsTotal();
            }}
            disableDelete={(index) => {
                const orderAdjustmentProducts = rx.getValues(
                    ORDER_ADJUSTMENT_PRODUCTS,
                ) as IOrderSale['order_adjustment_products'];

                const orderSaleProducts = rx.getValues(
                    ORDER_SALE_PRODUCTS,
                ) as IOrderSale['order_sale_products'];

                if (!orderSaleProducts) {
                    return false;
                }

                const orderSaleProduct = orderSaleProducts[index];

                const orderAdjustmentProduct = orderAdjustmentProducts?.find(
                    (oap) => {
                        return oap.product_id === orderSaleProduct.product?.id;
                    },
                );

                return !!orderAdjustmentProduct;
            }}
            items={[
                {
                    cellGrow: 5,
                    getField: (index) => {
                        return <OrderSaleProductInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return <GroupsInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return <GroupPriceInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return (
                            <RHTextField
                                disabled
                                name={`${ORDER_SALE_PRODUCTS}.${index}.${GROUP_WEIGHT}`}
                                label={'Peso'}
                                disableGutters
                            />
                        );
                    },
                },
                {
                    getField: (index) => {
                        return <KilosInput index={index} />;
                    },
                },
                {
                    getField: (index) => {
                        return <KiloPriceInput index={index} />;
                    },
                },
                {
                    cellGrow: 2,
                    getField: (index) => {
                        return (
                            <RHTextField
                                name={`${ORDER_SALE_PRODUCTS}.${index}.${TOTAL}`}
                                label={'Total'}
                                disableGutters
                                disabled
                            />
                        );
                    },
                },
            ]}
        />
    );
}
