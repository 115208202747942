import { useExpensesSummaryPageLazyQuery } from '../../../../services';
import {
    DBlock,
    DMonth,
    DPage,
    DPageHeader,
    DYear,
    useDMonth,
    useDYear,
} from '../../../dum';
import React, { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import {
    formatDate,
    formatDateTime,
    formatFloat,
    isNumber,
} from '../../../../helpers';
import dayjs from 'dayjs';
import { ExpenseSummaryExcelExportButton } from './expense-summary-excel-export-button';
import { ExpenseSummaryTabs } from './expense-summary-tabs';

type AccountsPages = {
    accountName: string;
    data: {
        transferIndex: number;
        transferType: 'Cargo' | 'Abono' | 'Traspaso entre cuentas' | '-';
        fromAccountName: string;
        toAccountName: string;
        transferDate: string;
        orderCode: number | string | undefined;
        receiptIssueDate: string;
        transferredAmount: string;
        receiptTotal: string;
        receiptTax: string;
        taxRetained: string;
        nonTaxRetained: string;
        notes: string;
        supplement: string;
        creditNote: string;
    }[];
}[];

type OrderSalePage = {
    invoiceCode: string;
    date: string;
    accountName: string;
    total: string;
    tax: string;
    invoiceTotal: string;
    transferReceiptsTotal: string;
    transfers: string;
    supplement: string;
    credit_note: string;
    notes: string;
    canceled: string;
}[];

type ExpensePage = {
    orderCode: string;
    date: string;
    accountName: string;
    tax: string;
    taxRetained: string;
    expenseResourcesTotalWithTax: string;
    transferReceiptsTotal: string;
    transfers: string;
    supplement: string;
    notes: string;
    canceled: string;
}[];

export type ExpenseSummaryWorkSheets = {
    accountsPages: AccountsPages;
    orderSalePage: OrderSalePage;
    expensePage: ExpensePage;
    canceledExpensePage: ExpensePage;
};

export function ExpensesSummary() {
    const { year, setYear } = useDYear({
        COMPONENT_KEY: ExpensesSummary.name + '_Year',
        defaultValue: dayjs().year(),
    });

    const { month, setMonth } = useDMonth({
        COMPONENT_KEY: ExpensesSummary.name + '_Month',
        defaultValue: dayjs().month(),
    });

    const isYearNumber = useMemo(() => {
        return isNumber(year);
    }, [year]);

    const isMonthNumber = useMemo(() => {
        return isNumber(month);
    }, [month]);

    const [query, { data }] = useExpensesSummaryPageLazyQuery();

    useEffect(() => {
        if (isMonthNumber && isYearNumber) {
            void query({
                variables: {
                    year: year,
                    month: month,
                },
            });
        }
    }, [query, month, year, isMonthNumber, isYearNumber]);

    const {
        workSheets,
        dashboard,
    }: {
        workSheets: ExpenseSummaryWorkSheets;
        dashboard: {
            orderSalesTax: number;
            expensesTax: number;
        };
    } = useMemo(() => {
        const accounts =
            data?.getAccounts.filter((account) => {
                return account.is_own && account.id !== 38;
            }) || [];

        const accountsPages: AccountsPages = [];

        accounts.forEach((account) => {
            const accountPageData: AccountsPages[number]['data'] = [];
            data?.getTransfers
                .filter((transfer) => {
                    return (
                        transfer.to_account?.id === account.id ||
                        transfer.from_account?.id === account.id
                    );
                })
                .forEach((transfer, transferIndex) => {
                    const fromAccount = transfer.from_account;
                    const toAccount = transfer.to_account;
                    const transferredDate = formatDateTime(
                        transfer.transferred_date,
                    );
                    const transferredAmount = transfer.amount;

                    if (transfer.transfer_receipts.length > 0) {
                        transfer.transfer_receipts.forEach(
                            (transferReceipt, index) => {
                                const orderSale = transferReceipt.order_sale;
                                const expense = transferReceipt.expense;

                                const orderCode =
                                    orderSale?.invoice_code ||
                                    expense?.order_code ||
                                    '';
                                const receiptIssueDate = orderSale?.date
                                    ? formatDate(orderSale.date)
                                    : expense?.date
                                    ? formatDate(expense.date)
                                    : '';
                                const receiptTotal =
                                    orderSale?.total_with_tax ||
                                    expense?.total_with_tax ||
                                    0;
                                const receiptTax =
                                    orderSale?.tax || expense?.tax || 0;
                                const taxRetained = expense?.tax_retained || 0;
                                const nonTaxRetained =
                                    expense?.non_tax_retained || 0;

                                const notes: string =
                                    orderSale?.notes || expense?.notes || '';

                                const supplement: string =
                                    expense?.require_supplement
                                        ? expense?.supplement_code
                                        : orderSale?.require_supplement
                                        ? orderSale?.supplement_code
                                        : 'NO APLICA';
                                const creditNote: string =
                                    orderSale?.require_credit_note
                                        ? `${
                                              orderSale.credit_note_code
                                          } (${formatFloat(
                                              orderSale.credit_note_amount,
                                          )})`
                                        : 'NO APLICA';
                                accountPageData.push({
                                    transferIndex: transferIndex + 1,
                                    transferType: fromAccount?.is_own
                                        ? 'Cargo'
                                        : 'Abono',
                                    fromAccountName: fromAccount?.name || '',
                                    toAccountName: toAccount?.name || '',
                                    transferDate: transferredDate,
                                    orderCode: orderCode,
                                    receiptIssueDate: receiptIssueDate,
                                    transferredAmount:
                                        index === 0
                                            ? formatFloat(transferredAmount)
                                            : '',
                                    receiptTotal: formatFloat(receiptTotal),
                                    receiptTax: formatFloat(receiptTax),
                                    taxRetained: formatFloat(taxRetained),
                                    nonTaxRetained: formatFloat(nonTaxRetained),
                                    notes: notes,
                                    supplement: supplement,
                                    creditNote: creditNote,
                                });
                            },
                        );
                    } else {
                        accountPageData.push({
                            transferIndex: transferIndex + 1,
                            transferType: 'Traspaso entre cuentas',
                            fromAccountName: fromAccount?.name || '',
                            toAccountName: toAccount?.name || '',
                            transferDate: transferredDate,
                            orderCode: '',
                            receiptIssueDate: '',
                            transferredAmount: formatFloat(transferredAmount),
                            receiptTotal: '',
                            receiptTax: '',
                            taxRetained: '',
                            nonTaxRetained: '',
                            notes: '',
                            supplement: 'NO APLICA',
                            creditNote: 'NO APLICA',
                        });
                    }
                });
            accountsPages.push({
                data: accountPageData,
                accountName: account.name,
            });
        });

        const orderSalesPage: OrderSalePage = [];

        const orderSales = data?.getOrderSales;

        orderSales?.forEach((orderSale) => {
            if (
                orderSale.account?.name.toLowerCase() ===
                'Proveedora del Panadero'.toLowerCase()
            ) {
                console.log(orderSale);
            }

            orderSalesPage.push({
                invoiceCode: String(orderSale.invoice_code),
                date: formatDate(orderSale.date),
                accountName: orderSale?.account?.name || '',
                total: formatFloat(orderSale.total_with_tax),
                tax: formatFloat(orderSale.tax),
                invoiceTotal: formatFloat(orderSale.invoice_total),
                transferReceiptsTotal: formatFloat(
                    orderSale.transfer_receipts_total,
                ),
                transfers: orderSale.transfer_receipts.reduce(
                    (acc, transferReceipt, index) => {
                        return (
                            acc +
                            `${
                                transferReceipt.transfer?.to_account
                                    ?.abbreviation
                            } ${formatDate(
                                transferReceipt.transfer?.transferred_date,
                            )} (${formatFloat(transferReceipt.amount)})` +
                            (index !== orderSale.transfer_receipts.length - 1
                                ? ', '
                                : '')
                        );
                    },
                    '',
                ),
                supplement: orderSale.require_supplement
                    ? orderSale.supplement_code === ''
                        ? 'PENDIENTE'
                        : orderSale.supplement_code
                    : 'N/A',
                credit_note: orderSale.require_credit_note
                    ? `${
                          orderSale.credit_note_code === ''
                              ? 'PENDIENTE'
                              : orderSale.credit_note_code
                      } ($${formatFloat(orderSale.credit_note_amount)})`
                    : 'N/A',
                notes: orderSale.notes,
                canceled: orderSale.canceled ? 'SI' : 'NO',
            });
        });

        const expenses = data?.getExpenses;

        const expensePage: ExpensePage = [];

        expenses?.forEach((expense) => {
            expensePage.push({
                orderCode: expense.order_code,
                date: formatDate(expense.date),
                accountName: expense?.account?.name || '',
                tax: formatFloat(expense.tax),
                taxRetained: formatFloat(expense.tax_retained),
                expenseResourcesTotalWithTax: formatFloat(
                    expense.total_with_tax,
                ),
                transferReceiptsTotal: formatFloat(
                    expense.transfer_receipts_total,
                ),
                transfers: expense.transfer_receipts.reduce(
                    (acc, transferReceipt, index) => {
                        return (
                            acc +
                            `${
                                transferReceipt.transfer?.from_account
                                    ?.abbreviation
                            } ${formatDate(
                                transferReceipt.transfer?.transferred_date,
                            )} (${formatFloat(transferReceipt.amount)})` +
                            (index !== expense.transfer_receipts.length - 1
                                ? ', '
                                : '')
                        );
                    },
                    '',
                ),
                supplement: expense.require_supplement
                    ? expense.supplement_code === ''
                        ? 'PENDIENTE'
                        : expense.supplement_code
                    : 'N/A',

                notes: expense.notes,
                canceled: expense.canceled ? 'SI' : 'NO',
            });
        });

        const canceledExpensePage: ExpensePage = [];

        const canceledExpenses = data?.getCanceledExpenses;

        canceledExpenses?.forEach((expense) => {
            canceledExpensePage.push({
                orderCode: expense.order_code,
                date: formatDate(expense.date),
                accountName: expense?.account?.name || '',
                tax: formatFloat(expense.tax),
                taxRetained: formatFloat(expense.tax_retained),
                expenseResourcesTotalWithTax: formatFloat(
                    expense.total_with_tax,
                ),
                transferReceiptsTotal: formatFloat(
                    expense.transfer_receipts_total,
                ),
                transfers: expense.transfer_receipts.reduce(
                    (acc, transferReceipt, index) => {
                        return (
                            acc +
                            `${
                                transferReceipt.transfer?.from_account
                                    ?.abbreviation
                            } ${formatDate(
                                transferReceipt.transfer?.transferred_date,
                            )} (${formatFloat(transferReceipt.amount)})` +
                            (index !== expense.transfer_receipts.length - 1
                                ? ', '
                                : '')
                        );
                    },
                    '',
                ),
                supplement: expense.require_supplement
                    ? expense.supplement_code === ''
                        ? 'PENDIENTE'
                        : expense.supplement_code
                    : 'N/A',

                notes: expense.notes,
                canceled: expense.canceled ? 'SI' : 'NO',
            });
        });

        return {
            workSheets: {
                accountsPages: accountsPages,
                orderSalePage: orderSalesPage,
                expensePage: expensePage,
                canceledExpensePage: canceledExpensePage,
            },
            dashboard: {
                orderSalesTax:
                    orderSales?.reduce((acc, curr) => {
                        return acc + curr.tax;
                    }, 0) || 0,
                expensesTax:
                    expenses?.reduce((acc, curr) => {
                        return acc + curr.tax;
                    }, 0) || 0,
            },
        };
    }, [data]);

    return (
        <DPage>
            <DPageHeader title={'Resumen de gastos'} />
            <DBlock>
                <Box sx={{ display: 'flex', mb: 4 }}>
                    <Box sx={{ mr: 1, flex: 1 }}>
                        <DYear setYear={setYear} year={year} />
                    </Box>
                    <Box sx={{ ml: 1, mr: 1, flex: 1 }}>
                        <DMonth setMonth={setMonth} month={month} />
                    </Box>
                    <Box sx={{ ml: 1 }}>
                        <ExpenseSummaryExcelExportButton
                            worksheets={workSheets}
                            disabled={!isMonthNumber || !isYearNumber}
                        />
                    </Box>
                </Box>
                <Box>
                    <Typography>Resumen</Typography>
                    <Box>
                        <p>
                            IVA en ventas:{' '}
                            {formatFloat(dashboard.orderSalesTax)}
                        </p>
                        <p>
                            IVA en gastos: {formatFloat(dashboard.expensesTax)}
                        </p>
                    </Box>
                </Box>
                <Box sx={{ overflowY: 'scroll' }}>
                    <ExpenseSummaryTabs worksheets={workSheets} />
                </Box>
            </DBlock>
        </DPage>
    );
}
