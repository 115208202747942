import { useApolloClient } from '@apollo/client';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import { SchemaOf, ValidationError } from 'yup';
import {
    RhCheckbox,
    RhDateInput,
    RhFormWrapper,
    RHTextField,
} from '../../../../../dum';
import {
    getDateFromString,
    getStringFromDate,
    transformToNumber,
} from '../../../../../../helpers';
import {
    queryIsOrderSaleCodeOccupied,
    useUpsertOrderSaleMutation,
} from '../../../../../../services';
import {
    AccountIdInput,
    InvoiceCodeInput,
    OrderCodeInput,
    OrderRequestInput,
    OrderSaleProductsColumns,
    OrderSaleReceiptTypeInput,
    OrderSaleStatusInput,
    TotalInput,
} from './inputs';
import {
    areGroupsAvailable,
    areKilosAvailable,
    doKiloPriceMatchOrderRequestProductKiloPrice,
    isProductUnique,
} from './validation';
import { doGroupPriceMatchOrderRequestProductGroupPrice } from './validation/do-group-price-match-order-request-product-group-price/do-group-price-match-order-request-product-group-price';
import { isKiloPriceAndGroupPriceDifferentThanZero } from './validation/is-kilo-price-and-group-price-different-than-zero';
import { RequireSupplementInput } from './inputs/require-supplement/require-supplement';
import { SupplementCodeInput } from './inputs/supplement-code/supplement-code';
import { RequireCreditNoteInput } from './inputs/require-credit-node/require-credit-note';
import { CreditNoteCodeInput } from './inputs/credit-note-amount/credit-note-amount';
import { CreditNoteAmountInput } from './inputs/credit-note-code/credit-note-code';
import { isAccountRequired } from './validation/is-account-required';
import { DSection } from '../../../../../dum/simple/section/d-section';
import { OrderAdjustmentProductsColumns } from './inputs/order-adjustment-products/order-adjustment-products-columns';
import { canKilosBeChangedWithOrderAdjustmentProducts } from './validation/can-kilos-be-changed-with-order-adjustment-products/can-kilos-be-changed-with-order-adjustment-products';
import { canGroupsBeChangedWithOrderAdjustmentProducts } from './validation/can-groups-be-changed-with-order-adjustment-products/can-groups-be-changed-with-order-adjustment-products';
import { TransferReceiptColumns } from './inputs/transfer-receipts/transfer-receipt-columns';
import { SubtotalInput } from './inputs/subtotal/subtotal-input';
import { TaxInput } from './inputs/tax/tax-input';
import { AutomaticTaxCalculationInput } from './inputs/automatic-tax-calculation/automatic-tax-calculation';

export interface IOrderSale {
    id?: number | null;
    date?: string | null;
    expected_payment_date?: string | null;
    order_code: number;
    invoice_code?: number | null;
    require_supplement: boolean;
    canceled: boolean;
    supplement_code?: string | null;
    require_credit_note: boolean;
    credit_note_code?: string | null;
    credit_note_amount?: number | null;
    order_sale_status_id?: number | null;
    account?: {
        id: number | null;
        requires_order_request: boolean;
    } | null;
    notes?: string;
    order_request?: {
        id: number;
        order_code: number;
        account?: {
            id: number | null;
            requires_order_request: boolean;
        } | null;
        order_request_products: {
            product_id?: number | null;
            kilos: number;
            groups: number;
            total: number;
        }[];
        order_request_remaining_products: {
            product_id?: number | null;
            product?: {
                id: number;
                compound_description: string;
                current_group_weight?: number | null;
                current_kilo_price?: number | null;
            };
            kilos: number;
            groups: number;
            kilo_price: number;
            group_weight: number;
            group_price: number;
            total: number;
        }[];
    } | null;
    receipt_type_id?: number | null;
    total_with_tax?: number | null;
    subtotal?: number | null;
    tax?: number | null;
    automatic_tax_calculation: boolean;
    order_sale_products: {
        id?: number | null;
        kilos: number;
        kilo_price: number;
        group_price: number;
        product?: {
            id: number;
            compound_description: string;
            current_group_weight?: number | null;
        } | null;
        groups: number;
        group_weight?: number | null;
        total: number;
    }[];
    order_adjustment_products?: {
        id?: number | null;
        kilos: number;
        product_id?: number | null;
        groups: number;
    }[];
    transfer_receipts?: {
        id?: number | null;
        amount: number;
        transferred_date?: string | null;
        transfer?: {
            transferred_date: string;
            to_account?: {
                compound_name: string;
            } | null;
        } | null;
        compound_name?: string | null;
    }[];
    transfer_receipts_total_no_adjustments?: number | null;
    products_total?: number | null;
}

export const REQUIRE_SUPPLEMENT: keyof IOrderSale = 'require_supplement';
export const ORDER_ADJUSTMENT_PRODUCTS: keyof IOrderSale =
    'order_adjustment_products';
export const TRANSFER_RECEIPTS: keyof IOrderSale = 'transfer_receipts';
export const PRODUCTS_TOTAL: keyof IOrderSale = 'products_total';
export const TOTAL_WITH_TAX: keyof IOrderSale = 'total_with_tax';
export const TAX: keyof IOrderSale = 'tax';
export const AUTOMATIC_TAX_CALCULATION: keyof IOrderSale =
    'automatic_tax_calculation';
export const SUBTOTAL: keyof IOrderSale = 'subtotal';
export const TRANSFER_RECEIPTS_TOTAL: keyof IOrderSale =
    'transfer_receipts_total_no_adjustments';
export const CANCELED: keyof IOrderSale = 'canceled';
export const SUPPLEMENT_CODE: keyof IOrderSale = 'supplement_code';
export const ORDER_REQUEST: keyof IOrderSale = 'order_request';
export const REQUIRE_CREDIT_NOTE: keyof IOrderSale = 'require_credit_note';
export const CREDIT_NOTE_AMOUNT: keyof IOrderSale = 'credit_note_amount';
export const CREDIT_NOTE_CODE: keyof IOrderSale = 'credit_note_code';

interface TOrderSale extends IOrderSale {
    id?: number | null;
}

interface ISOrderSaleFormProps {
    onSuccess: () => void;
    disabled?: boolean;
    orderSale?: TOrderSale;
}

export const OrderSaleForm = (props: ISOrderSaleFormProps) => {
    const { orderSale, onSuccess, disabled = false } = props;

    const apolloClient = useApolloClient();

    const [mutation, { loading }] = useUpsertOrderSaleMutation();

    const initialValues: IOrderSale = useMemo<TOrderSale>(() => {
        return {
            id: orderSale?.id || undefined,
            order_code: orderSale?.order_code || 0,
            invoice_code: orderSale?.invoice_code || 0,
            date: orderSale?.date ? getDateFromString(orderSale.date) : null,
            expected_payment_date: orderSale?.expected_payment_date
                ? getDateFromString(orderSale.expected_payment_date)
                : null,
            order_sale_status_id: orderSale?.order_sale_status_id
                ? orderSale.order_sale_status_id
                : null,
            notes: orderSale?.notes || '',
            account: orderSale?.account
                ? {
                      id: orderSale.account.id || null,
                      requires_order_request:
                          orderSale.account?.requires_order_request || false,
                  }
                : null,
            require_supplement: orderSale?.require_supplement || false,
            automatic_tax_calculation: orderSale
                ? orderSale.automatic_tax_calculation
                : true,
            tax: orderSale?.tax || 0,
            canceled: orderSale?.canceled || false,
            supplement_code: orderSale ? orderSale.supplement_code : '',
            require_credit_note: orderSale?.require_credit_note || false,
            credit_note_code: orderSale?.credit_note_code || '',
            credit_note_amount: orderSale?.credit_note_amount || 0,
            order_request:
                orderSale && orderSale.order_request
                    ? {
                          ...orderSale.order_request,
                          order_request_remaining_products: orderSale
                              .order_request.order_request_remaining_products
                              ? orderSale.order_request.order_request_remaining_products.map(
                                    (orderRemainingProduct) => {
                                        const orderSaleProduct =
                                            orderSale.order_sale_products.find(
                                                (orderSaleProduct) => {
                                                    return (
                                                        orderSaleProduct.product
                                                            ?.id ===
                                                        orderRemainingProduct
                                                            .product?.id
                                                    );
                                                },
                                            );
                                        const kilos = orderSaleProduct
                                            ? orderRemainingProduct.kilos +
                                              orderSaleProduct.kilos
                                            : orderRemainingProduct.kilos;
                                        const groups = orderSaleProduct
                                            ? orderRemainingProduct.groups +
                                              orderSaleProduct.groups
                                            : orderRemainingProduct.groups;
                                        return {
                                            ...orderRemainingProduct,
                                            kilos: kilos,
                                            groups: groups,
                                        };
                                    },
                                )
                              : [],
                      }
                    : null,
            total_with_tax: orderSale?.total_with_tax
                ? orderSale?.total_with_tax
                : 0,
            products_total: orderSale?.products_total
                ? orderSale?.products_total
                : 0,
            receipt_type_id: orderSale?.receipt_type_id
                ? orderSale.receipt_type_id
                : null,
            order_sale_products: orderSale?.order_sale_products || [
                {
                    kilos: 0,
                    groups: 0,
                    kilo_price: 0,
                    group_weight: 0,
                    group_price: 0,
                    product: null,
                    total: 0,
                },
            ],
            subtotal: orderSale?.subtotal || 0,
            order_adjustment_products:
                orderSale?.order_adjustment_products || [],
            transfer_receipts: orderSale?.transfer_receipts?.map((tr) => {
                return {
                    id: tr.id,
                    transferred_date: tr.transfer?.transferred_date || null,
                    amount: tr.amount || 0,
                    to_account: null,
                    compound_name: tr.transfer?.to_account?.compound_name || '',
                };
            }),
            transfer_receipts_total_no_adjustments:
                orderSale?.transfer_receipts_total_no_adjustments || 0,
        };
    }, [orderSale]);

    const validationSchema = useMemo<SchemaOf<IOrderSale>>(() => {
        return yup.object({
            id: yup.number().notRequired().nullable(),
            notes: yup.string(),
            order_code: yup
                .number()
                .transform(transformToNumber)
                .nullable()
                .required(`'Folio' es obligatorio`)
                .test('unique', async function (value, context): Promise<
                    boolean | ValidationError
                > {
                    if (!!value) {
                        const result = await queryIsOrderSaleCodeOccupied(
                            apolloClient,
                            {
                                OrderCode: value,
                                OrderSaleId: orderSale?.id || null,
                            },
                        );
                        if (result.data.isOrderSaleCodeOccupied) {
                            return context.createError({
                                message: `El folio ${value} se encuentra en uso`,
                            });
                        }
                        return true;
                    } else {
                        return true;
                    }
                }),
            invoice_code: yup.mixed().when('receipt_type_id', {
                is: (val: any) => {
                    return typeof val === 'number' && val === 2;
                },
                then: yup.mixed().required(),
                otherwise: yup.mixed().nullable().notRequired(),
            }),
            require_supplement: yup.boolean().required(),
            canceled: yup.boolean().required(),
            supplement_code: yup.string().nullable().notRequired(),
            require_credit_note: yup.boolean().required(),
            automatic_tax_calculation: yup.boolean().required(),
            tax: yup
                .number()
                .transform(transformToNumber)
                .required()
                .nullable(),
            credit_note_code: yup.string().nullable().notRequired(),
            credit_note_amount: yup
                .number()
                .transform(transformToNumber)
                .required()
                .nullable(),
            order_request: yup
                .mixed()
                .nullable()
                .test('is-account-required', isAccountRequired),
            date: yup.string().required(`'Fecha' es obligatorio`).nullable(),
            expected_payment_date: yup.string().notRequired().nullable(),
            account: yup
                .mixed()
                .required(`'Cliente' es obligatorio`)
                .nullable(),
            receipt_type_id: yup
                .number()
                .transform(transformToNumber)
                .nullable()
                .required(`'Tipo' es obligatorio`),
            order_sale_status_id: yup
                .number()
                .transform(transformToNumber)
                .nullable()
                .required(`'Status' es obligatorio`),
            products_total: yup.mixed().notRequired(),
            total_with_tax: yup.number().required(),
            subtotal: yup.number().required(),
            order_adjustment_products: yup.mixed().nullable(),
            transfer_receipts: yup.mixed().nullable(),
            transfer_receipts_total_no_adjustments: yup.mixed().notRequired(),
            order_sale_products: yup
                .array()
                .min(1, `'Productos' es obligatorio`)
                .of(
                    yup.object().shape({
                        id: yup.number().notRequired().nullable(),
                        product: yup
                            .mixed()
                            .nullable()
                            .required(`'Producto' es obligatorio`)
                            .test('is-product-unique', isProductUnique),
                        kilos: yup
                            .number()
                            .transform(transformToNumber)
                            .nullable()
                            .required('`Kilos` es obligatorio')
                            .min(0, `'Kilos' no puede ser menor a 0`)
                            .test('are-kilos-available', areKilosAvailable)
                            .test(
                                'can-kilos-be-changed-with-order-adjustment-products',
                                canKilosBeChangedWithOrderAdjustmentProducts,
                            ),
                        kilo_price: yup
                            .number()
                            .transform(transformToNumber)
                            .required(`'Precio' es obligatorio`)
                            .test(
                                'do-kilo-price-match-order-request-product-kilo-price-price',
                                doKiloPriceMatchOrderRequestProductKiloPrice,
                            )
                            .test(
                                'is-kilo-price-and-group-price-different-than-zero',
                                isKiloPriceAndGroupPriceDifferentThanZero,
                            ),
                        group_price: yup
                            .number()
                            .transform(transformToNumber)
                            .required(`'Precio' es obligatorio`)
                            .test(
                                'do-group-price-match-order-request-product-group-price-price',
                                doGroupPriceMatchOrderRequestProductGroupPrice,
                            )
                            .test(
                                'is-kilo-price-and-group-price-different-than-zero',
                                isKiloPriceAndGroupPriceDifferentThanZero,
                            ),
                        group_weight: yup
                            .number()
                            .transform(transformToNumber)
                            .required(`'Peso' es obligatorio`)
                            .min(0, `'Peso' no puede ser menor a 0`)
                            .nullable(),
                        groups: yup
                            .number()
                            .transform(transformToNumber)
                            .required(`'Grupos' es obligatorio`)
                            .min(0, `'Grupos' no puede ser menor a 0`)
                            .nullable()
                            .test('are-groups-available', areGroupsAvailable)
                            .test(
                                'can-groups-be-changed-with-order-adjustment-products',
                                canGroupsBeChangedWithOrderAdjustmentProducts,
                            ),
                        total: yup
                            .number()
                            .transform(transformToNumber)
                            .required(`'Total' es obligatorio`)
                            .nullable(),
                    }),
                )
                .required('Required'),
        });
    }, [apolloClient, orderSale?.id]);

    return (
        <RhFormWrapper
            validationSchema={validationSchema}
            initialValues={initialValues}
            disabled={loading || disabled}
            onSubmit={async (data) => {
                try {
                    await mutation({
                        variables: {
                            OrderSaleInput: {
                                id: orderSale?.id || null,
                                date: data.date
                                    ? getStringFromDate(data.date)
                                    : null,
                                account_id: data.account?.id || null,
                                expected_payment_date:
                                    data.expected_payment_date
                                        ? getStringFromDate(
                                              data.expected_payment_date,
                                          )
                                        : null,
                                canceled: data.canceled || false,
                                notes: data.notes || '',
                                order_sale_status_id:
                                    data.order_sale_status_id!,
                                order_request_id:
                                    data.order_request?.id || null,
                                receipt_type_id: data.receipt_type_id!,
                                order_code: data.order_code,
                                require_supplement:
                                    data.require_supplement || false,
                                supplement_code: data.supplement_code || '',
                                require_credit_note:
                                    data.require_credit_note || false,
                                automatic_tax_calculation: data
                                    ? data.automatic_tax_calculation
                                    : true,
                                tax: data.tax || 0,
                                subtotal: data.subtotal || 0,
                                credit_note_code: data.credit_note_code || '',
                                credit_note_amount:
                                    data.credit_note_amount || 0,
                                invoice_code:
                                    data.invoice_code &&
                                    !Number.isNaN(Number(data.invoice_code))
                                        ? Number(data.invoice_code)
                                        : 0,
                                order_sale_products:
                                    data.order_sale_products.map(
                                        (saleProduct) => {
                                            return {
                                                id: saleProduct.id || null,
                                                kilos: saleProduct.kilos,
                                                kilo_price:
                                                    saleProduct.kilo_price,
                                                group_price:
                                                    saleProduct.group_price,
                                                product_id:
                                                    saleProduct.product!.id,
                                                group_weight:
                                                    saleProduct.group_weight!,
                                                groups: saleProduct.groups,
                                            };
                                        },
                                    ),
                            },
                        },
                    });
                    onSuccess();
                } catch (e) {
                    console.error(e);
                }
            }}
            onInvalid={async (e) => {
                console.error(e);
            }}
        >
            <OrderSaleReceiptTypeInput />
            <DSection title={'Datos basicos'} alwaysOpen>
                <RhDateInput name={'date'} label={'Fecha de la factura/nota'} />
                <OrderRequestInput />
                <AccountIdInput />

                <OrderCodeInput />
                <InvoiceCodeInput />
                <OrderSaleStatusInput />
            </DSection>
            <DSection title={'Productos'} alwaysOpen>
                <OrderSaleProductsColumns />
                <SubtotalInput />
                <TaxInput />
                <TotalInput />
            </DSection>
            <DSection title={'Datos opcionales'}>
                <RhDateInput
                    name={'expected_payment_date'}
                    label={'Fecha de la próxima cobranza'}
                />
                <RequireSupplementInput />
                <SupplementCodeInput />
                <RequireCreditNoteInput />
                <CreditNoteCodeInput />
                <CreditNoteAmountInput />
                <RHTextField name={'notes'} label={'Notas'} rows={3} />
                <RhCheckbox name={CANCELED} label={'Cancelada'} />
                <AutomaticTaxCalculationInput />
            </DSection>
            <DSection
                title={'Devoluciones'}
                alwaysOpen={
                    (initialValues?.order_adjustment_products?.length || 0) > 0
                }
            >
                <OrderAdjustmentProductsColumns />
                <RHTextField
                    disabled
                    name={PRODUCTS_TOTAL}
                    label={'Total (menos devoluciones)'}
                />
            </DSection>

            <DSection
                title={'Transferencias'}
                alwaysOpen={(initialValues?.transfer_receipts?.length || 0) > 0}
            >
                <TransferReceiptColumns />
                <RHTextField
                    disabled
                    name={TRANSFER_RECEIPTS_TOTAL}
                    label={'Transferido total'}
                />
            </DSection>
        </RhFormWrapper>
    );
};
