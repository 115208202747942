import React from 'react';
import { AUTOMATIC_TAX_CALCULATION } from '../../order-sale-form';
import { RhCheckbox } from '../../../../../../../dum';
import { useCalculateProductsTotal } from '../../helpers/use-calculate-products-total';

export function AutomaticTaxCalculationInput() {
    const { calculateProductsTotal } = useCalculateProductsTotal();
    return (
        <RhCheckbox
            name={AUTOMATIC_TAX_CALCULATION}
            label={'Calcular iva'}
            onChange={() => {
                calculateProductsTotal();
            }}
        />
    );
}
