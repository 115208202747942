import {
    AUTOMATIC_TAX_CALCULATION,
    IOrderSale,
    TAX,
} from '../../order-sale-form';
import { RHTextField } from '../../../../../../../dum';
import { useCalculateProductsTotal } from '../../helpers/use-calculate-products-total';
import { useWatch } from 'react-hook-form';

export function TaxInput() {
    const { calculateProductsTotal } = useCalculateProductsTotal();
    const automaticTaxCalculation = useWatch({
        name: AUTOMATIC_TAX_CALCULATION,
    }) as IOrderSale['automatic_tax_calculation'];

    return (
        <RHTextField
            name={TAX}
            label={'IVA'}
            onChange={() => {
                calculateProductsTotal();
            }}
            disabled={automaticTaxCalculation}
        />
    );
}
